import * as React from 'react'
import { useEffect, useState } from 'react'

import { Container, Dropdown, DropdownButton, FormControl, Table } from 'react-bootstrap'

import LinkedIn from 'svgs/linkedin.svg'
// COMPONENTS
import Layout from 'layouts/main'

import '../styles/components.css'
import Api from '../helpers/api'
import ProfilePic from 'images/profile_placeholder.png'
import axios from 'axios'
import { config } from '../config'
import Loading from '../components/shared/loading'

const Grades = () => {

  const [students, setStudents] = useState([])

  const [loading, setLoading] = useState(true)

  const [selectedProgram, setSelectedProgram] = useState('')
  const [sort, setSort] = useState(0)
  const [user, setUser] = useState(null)
  const [accessDenied, setAccessDenied] = useState(false);

  const [studentImages, setStudentImages] = useState({})

  const PROGRAMS = {
    'fullStack': 'Full-Stack Development',
    'productManagement': 'Product Management',
    'backEnd': 'Back-End Development',
    'b2bSales': 'B2B Sales',
    'productDesign': 'Product Design',
    'digitalMarketing': 'Digital Marketing',
  }

  useEffect(() => {
    setLoading(true)
    setStudents([])
    Api.getInstance().getStudentGrades(selectedProgram, sort ? 'ascending' : 'descending').then(response => {
      setStudents(response.applicants)
    }).catch(e => {

    }).finally(() => {
      setLoading(false)
    })
  }, [selectedProgram, sort])

  useEffect(async () => {
    try {
      const token = window.localStorage.getItem('token')
      let user = (await axios.get(`${config.API_BASE_URL}/users/profile?token=${token}`)).data
      setUser(user)
      if(user.role === 'ROLE_STUDENT'){
        setAccessDenied(true);
      }
    } catch (e) {
      window.localStorage.removeItem('user')
      window.localStorage.removeItem('token')
      window.location.href = '/?showLogin=true&next=/grades'
    }

    Api.getInstance().getStudentImages().then(response => {
      setStudentImages(response)
    }).catch(e => {

    }).finally(() => {

    })
  }, [])

  let sortedStudents = []
  sortedStudents.push(...students.filter(student => {
    return !!studentImages[student.email]
  }))
  sortedStudents.push(...students.filter(student => {
    return !studentImages[student.email]
  }))

  return <Layout title='Students'>
    <Container className='pb-5'>
      <link
        href='https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css'
        rel='stylesheet'
        integrity='sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3'
        crossOrigin='anonymous'
      ></link>

      <h2 className='mt-5 pt-2 fw-medium'>Student Grades</h2>

      {!accessDenied && <Table className='table-grades mt-5 mb-5 font-size-16' responsive>
        <thead>
        <tr>
          <th className='text-uppercase d-none d-md-table-cell'>STUDENT NAME</th>
          <th className='d-block d-md-table-cell w-sm-100 w-100'>
            <DropdownButton className='calendar-select program-select outline-none w-100'
                            variant='outline-secondary'
                            title='PROGRAM'
                            id='input-group-dropdown-1'
            >
              <div className='px-3'>
                <FormControl
                  className='outline-none border-bottom border-top-0 border-start-0 border-end-0 rounded-0'
                  placeholder='Type program name'
                />
              </div>
              <Dropdown.Item href='#' onClick={() => {
                setSelectedProgram(null)
              }
              } className='py-2'>All</Dropdown.Item>
              {Object.keys(PROGRAMS).map(p => {
                return <Dropdown.Item href='#' onClick={() => {
                  setSelectedProgram(p)
                }
                } className='py-2'>{PROGRAMS[p]}</Dropdown.Item>
              })}
            </DropdownButton>
          </th>
          <th className='d-block d-md-table-cell text-lg-end w-sm-100'>
            <DropdownButton className='grade-select calendar-select py-0 outline-none w-100'
                            variant='outline-secondary'
                            title='GRADE'
                            id='input-group-dropdown-1'
            >
              <Dropdown.Item href='#' onClick={() => {
                setSort(0)
              }
              } className='py-2'>Highest to lowest</Dropdown.Item>
              <Dropdown.Item href='#' onClick={() => {
                setSort(1)
              }
              } className='py-2'>Lowest to highest</Dropdown.Item>
            </DropdownButton>
          </th>
        </tr>
        </thead>
        <tbody className='align-middle border-top-0 last-border-0'>
        {sortedStudents.map((student, i) => (
          <tr key={i}>
            <td>

              <img
                src={studentImages[student.email] ? `${config.CDN_BASE_URL}/${studentImages[student.email]}` : ProfilePic}
                style={{ width: 56, height: 56 }}
                className='ms-1 me-3 rounded-circle float-start float-md-none'
                alt=''
              />
              <div className='d-md-inline d-flex'>
                <a href='#' className='text-dark d-block d-md-inline'>
                  {student.fullName}
                </a>
                {student.linkedinURL &&
                <a href={student.linkedinURL.includes('https') ? student.linkedinURL : `https://${student.linkedinURL}`}
                   target='_blank' className='ms-2 linkedin'><LinkedIn /></a>
                }
              </div>


              <div className='d-inline-flex align-items-center d-md-none mb-3'>
                <small className='my-0 me-2'>Grade:</small>
                <strong className='fw-medium'>{student.score.toFixed(2)}/100</strong>
              </div>
            </td>
            <td>
              <a href='#' className='text-dark'>
                {student.program}
              </a>
            </td>

            <td className='text-lg-end'>
              <div
                className='badge border border-outline-secondary rounded px-3 py-2 text-dark'
                style={{ backgroundColor: studentImages[student.email] ? '#edfb71' : 'none' }}>{student.score.toFixed(2)}/100
              </div>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
      }

      {loading &&
      <div>
        <Loading/>
      </div>
      }

      {accessDenied &&
      <div>You don't have permission to access this page.</div>
      }
    </Container>
  </Layout>
}

export default Grades
